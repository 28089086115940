import L from 'leaflet/dist/leaflet.js'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'mil-no-sleep/mil-no-sleep.js';

let map = L.map('map').fitWorld();
let marker, markerTracker;

L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 19,
    attribution: '© OpenStreetMap'
}).addTo(map);


let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

if (!navigator.geolocation) {
    console.log("Your browser doesn't support geolocation feature!")
} else {
    setInterval(() => {
        navigator.geolocation.getCurrentPosition(getPosition)
    }, 5000);
};

function getPosition(position) {
    // console.log(position)
    const lat = position.coords.latitude
    const long = position.coords.longitude

    if (marker) {
        map.removeLayer(marker)
    }

    marker = L.marker([lat, long])

    marker.addTo(map)
    map.setView([lat, long], 17)
}

function setupWebsocket() {
    const websocket = new WebSocket("wss://ws.ttn-tracker.jonasled.de/endpoint/ws/ttnmapper");
    websocket.onmessage = function (msg) {
        console.log(msg.data)
        const data = JSON.parse(msg.data);
        if (markerTracker) {
            map.removeLayer(markerTracker)
        }
        markerTracker = L.marker([data.position.lat, data.position.lon])
        let markerText = "";
        data["gateways"].forEach(gateway => {
            markerText += gateway.name + ": " + gateway.rssi + " dBm <br>";
        });
        markerTracker.addTo(map).bindPopup(markerText).openPopup();
    }
    console.onclose = function (e) {
        console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
        setTimeout(function () {
            setupWebsocket();
        }, 1000);
    };

    console.onerror = function (err) {
        console.error('Socket encountered error: ', err.message, 'Closing socket');
        ws.close();
    };
}

setupWebsocket();
async function wakelock() {
    try {
        let wakeLock = await navigator.wakeLock.request('screen');
    } catch (err) {
        console.error(`${err.name}, ${err.message}`);
    }
}

wakelock();